import React, { useEffect, useRef } from 'react';
import {
	Box,
	Table,
	TableContainer,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableCaption,
	Text,
	Grid,
	Center,
	Select,
	FormLabel,
	GridItem,
	Checkbox,
	useColorModeValue,
	useBreakpointValue,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { MinusIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { IndicatorTip } from '../../Components/IndicatorTip/IndicatorTip';

const GoalsRank = ({ data }) => {
	const elementRef = useRef(null);
	// const [compararGoals, setCompararGoals] = React.useState(false);
	const [anoRef, setAnoRef] = React.useState('');
	const [anoComp, setAnoComp] = React.useState('');
	const [showComp, setShowComp] = React.useState(false);
	const [indicator, setIndicator] = React.useState('T01S01');
	const [indicatorTheme, setIndicatorTheme] = React.useState('Extrema Pobreza');
	const [indicatorTooltip, setIndicatorTooltip] = React.useState(
		'Proporção da população em situação extrema pobreza [cadastradas no Cadúnico  por município]',
	);

	const [avaliableYears, setAvaliableYears] = React.useState([]);

	const isDesktop = useBreakpointValue({ base: false, md: true });

	const {
		data: dataMirrorInfo,
		isFetching: isFetchingMirrorInfo,
		isError: isErrorMirrorInfo,
	} = useQuery(
		'indexLabelsJson',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br:444/indexlabels/json',
			);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 15 },
	);

	const {
		data: dataInfo,
		isFetching: isFetchingInfo,
		isError: isErrorInfo,
	} = useQuery(
		`indicatorRankingJson${indicator.toLowerCase()}`,
		async () => {
			const response = await axios.get(
				`https://idsm.ufpb.br:444/ranking/json/${indicator.toLowerCase()}`,
			);

			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	);

	const handleIndicatorChange = (event) => {
		const values = event.target.value.split(',');
		setIndicator(values[0]);
		setIndicatorTheme(values[1]);
		setIndicatorTooltip(values[2]);
	};

	useEffect(() => {
		if (!isFetchingInfo) {
			if (isErrorInfo) {
				setAvaliableYears(['Erro ao carregar os dados']);
			} else {
				console.log(dataInfo);
				const anos = Object.keys(dataInfo[0]);
				console.log(anos);
				let yearsList = [];
				for (let ano in anos) {
					//console.log(ano)
					if (!isNaN(anos[ano])) {
						//console.log(anos[ano])
						yearsList.push(anos[ano]);
					}
				}
				setAvaliableYears(yearsList);
				setAnoRef(yearsList[yearsList.length - 1]);
				setAnoComp(yearsList[0]);
				console.log(yearsList);
			}
		}
	}, [dataInfo, isErrorInfo, indicator]);

	if (isDesktop) {
		return (
			<Box w="100%" p={4}>
				<Center>
					<Grid templateColumns={'1fr 1fr 1fr'} gap={8}>
						<GridItem marginTop={'1.5'}>
							<FormLabel marginLeft={3}>Selecionar Indicador</FormLabel>
							<Select
								/*placeholder="Selecione um indicador"*/ defaultValue={'T01S01'}
								onChange={handleIndicatorChange}
							>
								{isFetchingMirrorInfo ? (
									<option>carregando...</option>
								) : (
									dataMirrorInfo?.map((item) => {
										return (
											<option
												key={item.codigoIndicador}
												value={[
													item.codigoIndicador,
													item.temaIndicador,
													item.nomeIndicador,
												]}
											>
												{item.temaIndicador}
											</option>
										);
									})
								)}
							</Select>
						</GridItem>
						<GridItem>
							<Grid
								display={'flex'}
								alignItems={'center'}
								justifyContent={'flex-start'}
								templateColumns={'3fr 1fr'}
							>
								<FormLabel marginTop={'1.5'}>Ano de Comparação</FormLabel>
								<Checkbox
									value={showComp}
									onChange={() => setShowComp(!showComp)}
								>
									Comparar
								</Checkbox>
							</Grid>
							<Select
								placeholder="Todos os anos"
								value={anoComp}
								onChange={(event) => setAnoComp(event.target.value)}
							>
								{isFetchingInfo ? (
									<option>carregando...</option>
								) : (
									avaliableYears?.map((item) => {
										return <option key={item}>{item}</option>;
									})
								)}
							</Select>
						</GridItem>
						<GridItem>
							<FormLabel marginTop={'1.5'}>Ano de Referência</FormLabel>
							<Select
								placeholder="Todos os anos"
								value={anoRef}
								onChange={(event) => setAnoRef(event.target.value)}
							>
								{isFetchingInfo ? (
									<option>carregando...</option>
								) : (
									avaliableYears?.map((item) => {
										return <option key={item}>{item}</option>;
									})
								)}
							</Select>
						</GridItem>
					</Grid>
				</Center>

				<Center>
					<Table
						variant="simple"
						colorScheme="blue"
						overflowX="auto"
						maxW={1200}
					>
						<TableCaption placement="top">
							<Text fontSize="xl">{indicatorTheme}</Text>
							<Text fontSize="md">{indicatorTooltip}</Text>
              <Text fontSize="md">A Meta 1.1.1 dos ODS define como objetivo atingit 0.0% de nível de pobreza extrema até 2030 </Text>
						</TableCaption>
						<Thead>
							<Tr>
								<Th>Cidade</Th>
								{showComp ? (
									<>
										<Th maxW={'200px'}>
											{/* {indicatorTheme} ({anoComp}){' '} */}
											Indicador ({anoComp}){' '}
											{/* <IndicatorTip tip={indicatorTooltip} /> */}
										</Th>
										<Th maxW={'200px'}>Ranking ({anoComp})</Th>
									</>
								) : null}

								<Th maxW={'200px'}>
									{/* {indicatorTheme} ({anoRef}){' '} */}
									Indicador ({anoRef}){' '}
									{/* <IndicatorTip tip={indicatorTooltip} /> */}
								</Th>
								{/* <Th>Código Cidade</Th> */}
								<Th maxW={'200px'}>Ranking ({anoRef})</Th>
								{!showComp ? <Th maxW={'200px'}>Estimativa para 2030</Th> : null}
								{!showComp ? <Th maxW={'200px'}>Meta IDSM</Th> : null}
								{/* <Th>Alteração no ranking</Th>
				<Th>Valor do Índice</Th> */}
							</Tr>
						</Thead>
						<Tbody>
							{dataInfo
								?.filter((item) => item[anoRef] !== null)
								.sort((a, b) => a[anoRef + '_ranked'] - b[anoRef + '_ranked'])
								.map((item, index) => {
									let alteracaoNoRanking =
										item[anoComp + '_ranked'] - item[anoRef + '_ranked'];
									if (item[anoComp + '_ranked'] == null) {
										alteracaoNoRanking = 0;
									}
									return (
										<Tr key={index}>
											<Td>{item.nomeMunicipio}</Td>
											{showComp ? (
												<>
													<Td>
														{item[anoComp] ? item[anoComp].toFixed(3) : '-'}
													</Td>
													<Td>
														{item[anoComp + '_ranked']
															? item[anoComp + '_ranked']
															: '-'}
													</Td>
												</>
											) : null}

											<Td>
												<Box
													display={'flex'}
													justifyContent={'flex-start'}
													gap={4}
												>
													{/* <Text color={ (item[anoRef] - item[anoComp]) == 0 ? "gray.50" : ((item[anoRef] - item[anoComp]) < 0 ? "idsm.greenCoolors.base" : "idsm.redCoolors.base")}>{((item[anoRef] - item[anoComp]) > 0 ? (item[anoRef] - item[anoComp]).toFixed(3) : ((item[anoRef] - item[anoComp])*-1).toFixed(3) ) }</Text> */}
													<Text>{(item[anoRef]*100).toFixed(1) + " %"}</Text>
												</Box>
											</Td>
											<Td>
												<Box display={'flex'} justifyContent={'flex-start'}>
													{showComp ? (
														<>
															{alteracaoNoRanking == 0 ? (
																<MinusIcon color={'gray.50'} />
															) : alteracaoNoRanking > 0 ? (
																<TriangleUpIcon
																	color={'idsm.greenCoolors.base'}
																/>
															) : (
																<TriangleDownIcon
																	color={'idsm.redCoolors.base'}
																/>
															)}
															<Text
																marginLeft={2}
																color={
																	alteracaoNoRanking == 0
																		? 'gray.50'
																		: alteracaoNoRanking > 0
																		? 'idsm.greenCoolors.base'
																		: 'idsm.redCoolors.base'
																}
															>
																{alteracaoNoRanking < 0
																	? alteracaoNoRanking * -1
																	: alteracaoNoRanking}
															</Text>
														</>
													) : null}

													<Text marginLeft={8}>{item[anoRef + '_ranked']}</Text>
												</Box>
											</Td>
											{!showComp ? 
												<>
												<Td >
													<Box display={'flex'} justifyContent={'flex-start'}>
														{/*
															comparar o ano de referencia mais recente
														 */}
														{(((item[anoComp]-item[anoRef])/(anoRef-anoComp)) * -(anoRef-anoComp)) + item[anoRef] > 0 ? (((((item[anoComp]-item[anoRef])/(anoRef-anoComp)) * -(anoRef-anoComp)) + item[anoRef])*100).toFixed(1) + " %" : "0 %"}
													</Box>
												</Td>
												<Td >
													<Box display={'flex'} justifyContent={'flex-start'}>0 %</Box>
												</Td>
												</>
											: null }
											
                      {/* <Td>
                        {((item[anoRef] / (item[2015].toFixed(3) - item[anoRef].toFixed(3)) / (anoRef - 2015)) * 100).toFixed(0) >= 0
                        ?
                        <Box display={'flex'} justifyContent={'flex-start'}>
                          <Text>{((item[anoRef] / (item[2015].toFixed(3) - item[anoRef].toFixed(3)) / (anoRef - 2015)) * 100).toFixed(0)} anos</Text>
                        </Box>
                        :
                        <Box display={'flex'} justifyContent={'flex-start'}>
                          <Text>Não está progredindo</Text>
                        </Box>
                        }
                        
                      </Td> */}
											{/* <Td>{item.changeInRank}</Td>
				  <Td>{item.indexValue}</Td> */}
										</Tr>
									);
								})}
						</Tbody>
					</Table>
				</Center>
			</Box>
		);
	} else {
		return (
			<Box w="100vw" p={4}>
				<Center>
					<Grid templateColumns={['1fr', null, '1fr 1fr 1fr']} maxW="100%" gap={4}>
						<GridItem maxW="100%" marginTop={'1.5'}>
							<FormLabel marginLeft={1.5}>Selecionar Indicador</FormLabel>
							<Select defaultValue={'T01S01'} onChange={handleIndicatorChange}>
								{isFetchingMirrorInfo ? (
									<option>carregando...</option>
								) : (
									dataMirrorInfo?.map((item) => (
										<option
											key={item.codigoIndicador}
											value={[
												item.codigoIndicador,
												item.temaIndicador,
												item.nomeIndicador,
											]}
										>
											{item.temaIndicador}
										</option>
									))
								)}
							</Select>
						</GridItem>

						<GridItem maxW="100%">
							<Grid
								templateColumns={['1fr', null, '3fr 1fr']}
								gap={4}
								alignItems={'center'}
							>
								<FormLabel marginTop={'1.5'}>Ano de Comparação</FormLabel>
								<Checkbox
									value={showComp}
									onChange={() => setShowComp(!showComp)}
								>
									Comparar
								</Checkbox>

								<Select
									placeholder="Todos os anos"
									value={anoComp}
									onChange={(event) => setAnoComp(event.target.value)}
								>
									{isFetchingInfo ? (
										<option>carregando...</option>
									) : (
										avaliableYears?.map((item) => (
											<option key={item}>{item}</option>
										))
									)}
								</Select>
							</Grid>
						</GridItem>

						<GridItem maxW="100%">
							<FormLabel marginTop={'1.5'}>Ano de Referência</FormLabel>
							<Select
								placeholder="Todos os anos"
								value={anoRef}
								onChange={(event) => setAnoRef(event.target.value)}
							>
								{isFetchingInfo ? (
									<option>carregando...</option>
								) : (
									avaliableYears?.map((item) => (
										<option key={item}>{item}</option>
									))
								)}
							</Select>
						</GridItem>
					</Grid>
				</Center>

				<Box width="100%" overflowX="auto" >
					<Center>
						<Box
							ref={elementRef}
							flexDirection="column"
							padding={1}
							maxWidth="100vw"
							
						>
							<TableContainer
								userSelect="none"
								overflowX="auto"
								maxH="78vh"
								overflowY="auto"
							>
								<Table variant="simple" colorScheme="blue">
									<TableCaption placement="top">
										Ranking dos Municípios
									</TableCaption>
									<Thead>
										<Tr>
											<Th>Cidade</Th>
											{showComp ? (
												<>
													<Th maxW={'200px'}>
														{indicatorTheme} ({anoComp}){' '}
														<IndicatorTip tip={indicatorTooltip} />
													</Th>
													<Th maxW={'200px'}>Ranking ({anoComp})</Th>
												</>
											) : null}

											<Th maxW={'200px'}>
												{indicatorTheme} ({anoRef}){' '}
												<IndicatorTip tip={indicatorTooltip} />
											</Th>
											<Th maxW={'200px'}>Ranking ({anoRef})</Th>
										</Tr>
									</Thead>
									<Tbody>
										{dataInfo
											?.filter((item) => item[anoRef] !== null)
											.sort(
												(a, b) => a[anoRef + '_ranked'] - b[anoRef + '_ranked'],
											)
											.map((item, index) => {
												let alteracaoNoRanking =
													item[anoComp + '_ranked'] - item[anoRef + '_ranked'];
												if (item[anoComp + '_ranked'] == null) {
													alteracaoNoRanking = 0;
												}
												return (
													<Tr key={index}>
														<Td>{item.nomeMunicipio}</Td>
														{showComp ? (
															<>
																<Td>
																	{item[anoComp]
																		? item[anoComp].toFixed(3)
																		: '-'}
																</Td>
																<Td>
																	{item[anoComp + '_ranked']
																		? item[anoComp + '_ranked']
																		: '-'}
																</Td>
															</>
														) : null}

														<Td>
															<Box
																display={'flex'}
																justifyContent={'flex-start'}
																gap={4}
															>
																<Text>{item[anoRef]?.toFixed(3)}</Text>
															</Box>
														</Td>
														<Td>
															<Box
																display={'flex'}
																justifyContent={'flex-start'}
															>
																{showComp ? (
																	<>
																		{alteracaoNoRanking == 0 ? (
																			<MinusIcon color={'gray.50'} />
																		) : alteracaoNoRanking > 0 ? (
																			<TriangleUpIcon
																				color={'idsm.greenCoolors.base'}
																			/>
																		) : (
																			<TriangleDownIcon
																				color={'idsm.redCoolors.base'}
																			/>
																		)}
																		<Text
																			marginLeft={2}
																			color={
																				alteracaoNoRanking == 0
																					? 'gray.50'
																					: alteracaoNoRanking > 0
																					? 'idsm.greenCoolors.base'
																					: 'idsm.redCoolors.base'
																			}
																		>
																			{alteracaoNoRanking < 0
																				? alteracaoNoRanking * -1
																				: alteracaoNoRanking}
																		</Text>
																	</>
																) : null}
																<Text marginLeft={2}>
																	{item[anoRef + '_ranked']}
																</Text>
															</Box>
														</Td>
													</Tr>
												);
											})}
									</Tbody>
								</Table>
							</TableContainer>
						</Box>
					</Center>
				</Box>
			</Box>
		);
	}
};

export default GoalsRank;
