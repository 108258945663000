export const dados = {
  "dados": {
      "indicador": {
          "1": {
              "tema": 1,
              "eixo": 1,
              "anosDisponiveis": [
                  2019,
                  2020
              ],
              "nome": "Extrema Pobreza",
              "descricao": "Percentual da população em situação de Extrema Pobreza"
          },
          "2": {
              "tema": 1,
              "eixo": 1,
              "anosDisponiveis": [
                  2018,
                  2019,
                  2020
              ],
              "nome": "Proteção Social",
              "descricao": "Percentual da população recebendo benefícios sociais"
          },
          "3": {
              "tema": 2,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Peso ao Nascer",
              "descricao": "Percentual de nascidos vivos com peso ao nascer inferior a 2.500 gramas"
          },
          "4": {
              "tema": 3,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Mortalidade Infantil",
              "descricao": "Número de óbitos de menores de um ano de idade a cada mil nascidos vivos"
          },
          "5": {
              "tema": 3,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Mortalidade DCNT",
              "descricao": "Número de óbitos prematuros (entre 30 e 69 anos) por DCNT a cada mil habitantes"
          },
          "6": {
              "tema": 3,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Cobertura Universal à Saúde",
              "descricao": "Número de médicos atuando em APS a cada mil habitantes"
          },
          "7": {
              "tema": 3,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Saúde Sexual e Reprodutiva",
              "descricao": "Percentual de nascidos vivos com atendimento neo-natal adequado"
          },
          "8": {
              "tema": 4,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Discriminação e Participação Política",
              "descricao": "Percentual de mulheres no poder legislativo municipal"
          },
          "9": {
              "tema": 4,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Discriminação e Participação Política",
              "descricao": "Percentual de mulheres no poder legislativo municipal"
          },
          "10": {
              "tema": 4,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Violência Contra a Mulher",
              "descricao": "Percentual de mulheres de 15 anos ou mais que sofreram violência física a cada mil habitantes"
          },
          "11": {
              "tema": 4,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Desigualdade de Gênero nas Admissões",
              "descricao": "Proporção de mulheres contratadas em relação ao total de admissões"
          },
          "12": {
              "tema": 5,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Média de Alunos por Turma",
              "descricao": "Média de alunos por turma nos anos iniciais, quanto maior, pior"
          },
          "13": {
              "tema": 5,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Distorção idade/série",
              "descricao": "Percentual de alunos com mais de dois anos de atraso em relação a idade ideal para a série"
          },
          "14": {
              "tema": 5,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Complexidade da gestão escolar",
              "descricao": "Pontuação de complexidade da gestão escolar, quanto maior, pior"
          },
          "15": {
              "tema": 5,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Proficiência",
              "descricao": "Média dos resultados do IDEB em português e matemática"
          },
          "16": {
              "tema": 5,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Inclusão Digital",
              "descricao": "Percentual de escolas com acesso a internet banda larga"
          },
          "17": {
              "tema": 6,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Acesso à Telefonia Móvel ",
              "descricao": "Percentual da população com acesso a dados móveis"
          },
          "18": {
              "tema": 6,
              "eixo": 1,
              "anosDisponiveis": [],
              "nome": "Acesso à Internet nos Municípios",
              "descricao": "Percentual da população com acesso a banda larga fixa"
          },
          "19": {
              "tema": 7,
              "eixo": 2,
              "anosDisponiveis": [],
              "nome": "Crescimento do PIB",
              "descricao": "Percentual de crescimento do PIB em relação ao ano anterior"
          },
          "20": {
              "tema": 7,
              "eixo": 2,
              "anosDisponiveis": [],
              "nome": "Participação da Indústria no PIB",
              "descricao": "Percentual de contribuição da industria para o PIB em relação aos outros municipios do estado"
          },
          "21": {
              "tema": 7,
              "eixo": 2,
              "anosDisponiveis": [],
              "nome": "Participação da Agropecuária no PIB",
              "descricao": "Percentual de contribuição da agropecuária para o PIB em relação aos outros municipios do estado"
          },
          "22": {
              "tema": 7,
              "eixo": 2,
              "anosDisponiveis": [],
              "nome": "Participação dos Serviços no PIB",
              "descricao": "Percentual de contribuição de serviços para o PIB em relação aos outros municipios do estado"
          },
          "23": {
              "tema": 7,
              "eixo": 2,
              "anosDisponiveis": [],
              "nome": "Impacto do Setor Público no PIB",
              "descricao": "Percentual de contribuição do setor público para o PIB do municipio"
          },
          "24": {
              "tema": 8,
              "eixo": 2,
              "anosDisponiveis": [],
              "nome": "Empregos Formais",
              "descricao": "Percentual da população total com empregos formais na iniciativa privada "
          },
          "25": {
              "tema": 8,
              "eixo": 2,
              "anosDisponiveis": [],
              "nome": "Criação de Emprego",
              "descricao": "Variação Percentual de novos empregos formais em relação ano anterior"
          },
          "26": {
              "tema": 9,
              "eixo": 2,
              "anosDisponiveis": [],
              "nome": "Áreas Verdes I",
              "descricao": "Variação percentual da cobertura florestal em relação ao território antrópico no ano anterior"
          },
          "27": {
              "tema": 9,
              "eixo": 3,
              "anosDisponiveis": [],
              "nome": "Áreas Verdes II",
              "descricao": "Percentual de área natural em relação ao território antrópico"
          },
          "28": {
              "tema": 10,
              "eixo": 3,
              "anosDisponiveis": [],
              "nome": "Áreas Aquáticas",
              "descricao": "Desvio padrão da área de cobertura territorial de água*"
          },
          "29": {
              "tema": 11,
              "eixo": 3,
              "anosDisponiveis": [],
              "nome": "Emissão de Carbono",
              "descricao": "Emissão Bruta de Carbono por habitante*"
          },
          "30": {
              "tema": 12,
              "eixo": 3,
              "anosDisponiveis": [],
              "nome": "Qualidade da Água",
              "descricao": "Índice de Qualidade da Água*"
          },
          "31": {
              "tema": 13,
              "eixo": 4,
              "anosDisponiveis": [],
              "nome": "Autonomia Financeira",
              "descricao": "Percentual de receita própria em relação a receita orçamentária total"
          },
          "32": {
              "tema": 13,
              "eixo": 4,
              "anosDisponiveis": [],
              "nome": "Liquidez",
              "descricao": "Gasto com pessoal e encargos sociais/Receita corrente líquida*"
          },
          "33": {
              "tema": 13,
              "eixo": 4,
              "anosDisponiveis": [],
              "nome": "Endividamento Público",
              "descricao": "Dívida/receita corrente líquida*"
          },
          "34": {
              "tema": 13,
              "eixo": 4,
              "anosDisponiveis": [],
              "nome": "Taxa de Investimento Público Per Capta",
              "descricao": "Taxa de Investimento público per capta em reais"
          },
          "35": {
              "tema": 14,
              "eixo": 4,
              "anosDisponiveis": [],
              "nome": "Violência",
              "descricao": "Número de homicídios a cada 100 mil habitantes"
          },
          "36": {
              "tema": 14,
              "eixo": 4,
              "anosDisponiveis": [],
              "nome": "Vitimização de Jovens",
              "descricao": "Número de homicídios de jovens (entre 15 e 29 anos) a cada 100 mil habitantes"
          },
          "37": {
              "tema": 14,
              "eixo": 4,
              "anosDisponiveis": [],
              "nome": "Mortes por Armas de Fogo",
              "descricao": "Número de homicídios por arma de fogo a cada 100 mil habitantes"
          }
      },
      "idsm": {
          "anosDisponiveis": [
              2020,
              2019
          ],
          "descricao": "lorem ipsum"
      },
      "eixo": {
          "1": {
              "anosDisponiveis": [],
              "nome": "Social",
              "descricao": "social"
          },
          "2": {
              "anosDisponiveis": [
                  2019,
                  2020
              ],
              "nome": "Econômico",
              "descricao": "Econômico"
          },
          "3": {
              "anosDisponiveis": [],
              "nome": "Ambiental",
              "descricao": "ambiental"
          },
          "4": {
              "anosDisponiveis": [],
              "nome": "Institucional",
              "descricao": "Institucional"
          }
      }
  }
}