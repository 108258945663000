
import { useState, useEffect } from 'react';
import {
	ChakraProvider,
	Box,
	VStack,
	HStack,
	Button,
	Select,
	useBreakpointValue,
	FormLabel,
	useColorModeValue,
	Text,
	Divider,
	Input,
  Grid,
	Center,
  GridItem,
  Flex,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Checkbox
} from '@chakra-ui/react';
import { CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { dados } from './dados';
import { useQuery } from 'react-query';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { InputWithSuggestions } from '../../Components/Utils/InputSugestions/InputWithSuggestions';

function DownloadsPage() {
	const [url, setUrl] = useState('');
	// IA == Indicador por Ano | SH == Série Histórica | ID == Indice por Ano
	const [dataTypeIA, setDataTypeIA] = useState('');
	const [dataTypeSH, setDataTypeSH] = useState('');
	const [indicator, setIndicator] = useState('');
	const [bruteQuery, setBruteQuery] = useState('');
	const [yearIA, setYearIA] = useState('');
	const [yearID, setYearID] = useState('');
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [inputValue, setInputValue] = useState('');
	const bgColor = useColorModeValue('gray.100', 'idsm.darkBlueCoolors.7');
	const bgColorHover = useColorModeValue('gray.100', 'idsm.darkBlueCoolors.base');
	const [notFoundMessage, setNotFoundMessage] = useState('Nenhum município encontrado');
	const bgColor2 = useColorModeValue('gray.200', 'idsm.darkBlueCoolors.6');
	const bgColorScroll = useColorModeValue('gray.300', 'idsm.darkBlueCoolors.9');
	const [selectedCity, setSelectedCity] = useState(["250010", "250020"]);
	const [period, setPeriod] = useState([2015, 2020]);
	const [periodLabel, setPeriodLabel] = useState([2015, 2020]);
	const [selectedYears, setSelectedYears] = useState([2015, 2016, 2017, 2018, 2019, 2020]);
	const [queryGetData, setQueryGetData] = useState({
		'municipios': [],
		'indicadores': [],
		'eixos': [],
		'indices': [],
		'periodo': [2015, 2020],
	})

	const addCityNew = (city) => {
		setSelectedCity(addElementFilter(selectedCity, city))
	}

	const isMobile = useBreakpointValue({ base: true, md: false });
	const buttonColor = useColorModeValue('gray.600', 'gray.200');

	const handleShowSuggestions = (value) => {
		setShowSuggestions(value)
	}

	const periodInterval = () => {
		const minimo = period[0];
		const maximo = period[1];
		if(minimo > maximo) {
			throw new Error('O valor mínimo deve ser menor ou igual ao valor máximo.');
		}
		let result = [];
		for (let i = minimo; i <= maximo; i++) {
			result.push(i);
		}
		setSelectedYears(result);
		return result;
	}

	const listaIndicadores = () => {
		const listaIndicadores = dataInfo?.map((item) => {
			return (
				<><Checkbox  paddingX={"2"} paddingY={"1"} justifyContent={"space-between"} key={item.codigoIndicador} value={item.codigoIndicador} ><Text fontSize={14}>{item.temaIndicador}</Text></Checkbox><Divider /></>
			)
		})
			// console.log(item)
			
			
		return listaIndicadores;
	
	}

	useEffect(() => {
		periodInterval()
		console.log(selectedYears);
	}, [period]);

	const { data: yearsIndex, isFetching: isFetchingYearsIndex } = useQuery(
		'avaliableYearsIDSM',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br:444/avaliableyearsidsm',
			);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	);

	const { data: dataInfo, isFetching: isFetchingInfo } = useQuery(
		'indexLabelsJson',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br:444/indexlabels/json',
			);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	);

	async function DownloadDataIndicatorYear() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/indicator/xlsx/${dataTypeIA}/${indicator.toLowerCase()}/${yearIA}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			saveAs(blob, `${dataTypeIA}${indicator.toLowerCase()}${yearIA}.xlsx`);
			return response.data;
		}
	}

	async function DownloadBruteQuery() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/brutequery/${bruteQuery.toLowerCase()}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			saveAs(blob, `${bruteQuery.toLowerCase()}.xlsx`);
			return response.data;
		}
	}

	async function DownloadDataHistory() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/history/xlsx/${dataTypeSH}/${indicator.toLowerCase()}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			saveAs(
				blob,
				`${dataTypeSH}${indicator.toLowerCase()}_serie_historica.xlsx`,
			);
			return response.data;
		}
	}

	async function DownloadDataIndex() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/idsmindex/xlsx/${yearID}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			saveAs(blob, `IDSM${yearID}.xlsx`);
			return response.data;
		}
	}


	const handleChangeIndicatorIA = (event) => {
		setIndicator(event.target.value);
		console.log(event.target.value);
	};

	const handleChangeIndicatorSH = (event) => {
		setIndicator(event.target.value);
		console.log(event.target.value);
	};

	const handleChangeDataTypeIA = (event) => {
		setDataTypeIA(event.target.value);
		console.log(event.target.value);
	};
	const handleChangeDataTypeSH = (event) => {
		setDataTypeSH(event.target.value);
		console.log(event.target.value);
	};

	const handleChangeYearIA = (event) => {
		console.log(event.target.value);
		setYearIA(event.target.value);
		console.log(event.target.value);
	};
	const handleChangeYearID = (event) => {
		console.log(event.target.value);
		setYearID(event.target.value);
		console.log(event.target.value);
	};

	const handleBruteQuery = (event) => {
		console.log(event.target.value);
		setBruteQuery(event.target.value);
		console.log(event.target.value);
	};



	const elementosAno = () => {
		const listaAnos = dataInfo?.map((item) => {
			// console.log(item)
			let listaAnos = [];
			if (item.codigoIndicador === indicator) {
				const anos = item.anosDisponiveis.slice(1, -1).split(',').map(Number);
				anos.sort(function (a, b) {
					return a - b;
				});
				listaAnos = anos.map((year) => {
					return (
						<option key={year} value={year}>
							{year}
						</option>
					);
				});
			}
			return listaAnos;
		});
		return listaAnos;
	};

	const anosIndice = () => {
		const listaAnos = yearsIndex.map((year) => {
			console.log(year.anos)
					return (
						<option key={year.anos} value={year.anos}>
							{year.anos}
						</option>
						
					);
		});
		return listaAnos;
	}


	return (
		<VStack spacing={8} p={8}>
				<Box height={"100%"}>
					<VStack
						spacing={2}
						width="80vw"
						height={"100%"}
						maxHeight="100%"
						minWidth="240px"
						maxWidth="1200px"
						alignItems="flex-start"
					>
            {/* <HStack spacing={2} width="100%"> */}
            <Grid padding={'8'}  paddingTop={8} bg={'idsm.darkBlueCoolors.base'} rounded={'xl'} templateColumns="3fr 1fr 1fr" w={'100%'} h={'100%'}  maxH="70vh" gap={6}>
              <GridItem maxHeight={"100%"} >
                <Text height={'10%'} fontSize={'medium'} >Downloads</Text>
                <Flex  w={'100%'} h={'100%'} maxH={"60vh"} rounded={'xl'}>
								<GridItem marginTop={2} width={"100%"}>
											
											{/* <Input placeholder="Selecione uma mesoregião" list='municipios' value={inputValue} onChange={(event) => setInputValue(event.target.value)}/> */}
											<InputWithSuggestions
											suggestions={suggestions}
											inputValue={inputValue}
											setInputValue={setInputValue}
											showSuggestions={showSuggestions}
											setShowSuggestions={setShowSuggestions}
											handleShowSuggestions={handleShowSuggestions}
											notFoundMessage = {notFoundMessage}
											bgColor = {bgColor}
											bgColorHover = {bgColorHover}
											addCityFilter = {addCityNew}
											title = {'Municípios e Regiões'}
											/>
											<FormLabel marginLeft={4} marginTop={8}>Selecione o Periodo</FormLabel>
											<Box paddingLeft={6} paddingRight={6} marginBottom={4} w={'100%'} maxW={'100%'}>
											<RangeSlider aria-label={['min', 'max']} defaultValue={period} min={2013} max={2021} minStepsBetweenThumbs={1} step={1} onChange={(val) => { val == period ? null : setPeriodLabel(val)}} onChangeEnd={(val) => setPeriod(val)}>
												<RangeSliderTrack>
													<RangeSliderFilledTrack />
												</RangeSliderTrack>
												<RangeSliderThumb index={0} >
													<Text fontSize={'16'} marginTop={8}>{periodLabel[0]}</Text>
												</RangeSliderThumb>
												<RangeSliderThumb index={1} >
													<Text fontSize={'16'} marginTop={8}>{periodLabel[1]}</Text>
												</RangeSliderThumb>
											</RangeSlider>
											</Box>
											<Button X={"0"} marginTop={"4"}>
												Baixar Dados
												<DownloadIcon marginX={""}/>
											</Button>
									</GridItem>
								</Flex>
              </GridItem>
              <GridItem h="100%" maxHeight={"100%"} >
              <Text height={'10%'} fontSize={'medium'}>Indicadores e Índices</Text>
                <Flex bg={'idsm.darkBlueCoolors.8'} w={'100%'} maxH="60vh" h={'85%'} overflowY={'auto'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                  <VStack >
                  <FormLabel paddingTop={2}>Índices</FormLabel>
                  <VStack alignItems={'center'} w={'100%'}>
										<Divider />
                    <Checkbox>IDSM</Checkbox><Divider />
										<Checkbox><Text fontSize={14}>Eixo Social</Text></Checkbox><Divider />
										<Checkbox><Text fontSize={14}>Eixo Econômico</Text></Checkbox><Divider />
										<Checkbox><Text fontSize={14}>Eixo Ambiental</Text></Checkbox><Divider />
										<Checkbox><Text fontSize={14}>Eixo Institucional</Text></Checkbox><Divider />
                  </VStack>
                  <FormLabel>Indicadores</FormLabel>
                  <VStack>
										<Divider/>
                    {listaIndicadores()}
                  </VStack>
                  </VStack>
                  
                </Flex>
              </GridItem>
              <GridItem  maxHeight={"100%"} >
              <Text height={'10%'} fontSize={'medium'} >Municípios Selecionados</Text>
                <Flex bg={'idsm.darkBlueCoolors.8'} w={'100%'} h={'85%'} maxH="60vh" overflowY={'auto'}>
                  <VStack alignItems={'center'} w={'100%'}>
										<Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
										<HStack w="100%" paddingX={"2"} justifyContent={"space-between"}><Text fontSize={14}>Município Y</Text><Button height={'8'}><CloseIcon /> </Button></HStack><Divider/>
                  </VStack>
                </Flex>
              </GridItem>
            </Grid>
					</VStack>
				</Box>
		</VStack>
	);
}

export default DownloadsPage;
