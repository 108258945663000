import { Box, Text, Center, Select, Flex } from '@chakra-ui/react';
import PostCard from '../../Components/PostCard/PostCard';
import { useQuery } from 'react-query';
import { useState } from 'react';
import axios from 'axios';
export default function Observatory() {
	const [selectedEixoPost, setSelectedEixoPost] = useState(0);
	const [selectedCategoriaPost, setSelectedCategoriaPost] = useState(1);

	const {
		data: postsLabel,
		isFetching: isFetchingPostsLabel,
		isError: isErrorPostsLabel,
	} = useQuery(
		`postsEixo${selectedEixoPost}Categoria${selectedCategoriaPost}`,
		async () => {
			console.log(selectedEixoPost, selectedCategoriaPost);
			const response = await axios.get(
				`https://ods.ufpb.br/api/posts?eixoPostId=${selectedEixoPost}&categoriaPostId=${selectedCategoriaPost}`,
			);
			console.log(response.data);
			return response.data;
		},
		{
			refetchOnWindowFocus: true,
			staleTime: 1000 * 60 * 60, // 60 minutes
		},
	);

	function handleFileChange(event) {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = function () {
				// O resultado contém a string base64
				const base64String = reader.result;
				// Agora você pode enviar esta string para o servidor ou fazer o que precisar
			};
			reader.readAsDataURL(file);
		}
	}

	return (
		<Flex
			w="100%"
			maxW={'100%'}
			p={4}
			flexDir={'column'}
			alignItems={'center'}
			marginBottom={'200'}
		>
			<Flex
				flexDir={'row'}
				width={'100%'}
				maxWidth={'1200px'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				padding={8}
				columnGap={4}
			>
				<Select defaultValue={'0'} w={200}>
					<option value="0">Todos os Eixos</option>
					<option value="1">Social</option>
					<option value="2">Econômico</option>
					<option value="3">Ambiental</option>
					<option value="4">Institucional</option>
				</Select>
				<Select defaultValue={'1'} w={200}>
					<option value="1">Interno</option>
					<option value="2">Comunidade</option>
				</Select>
			</Flex>
			<Center flexDir={'column'} rowGap={8}>
				{isFetchingPostsLabel && <Text>Carregando...</Text>}
				{isErrorPostsLabel && <Text>Ocorreu um erro ao carregar os posts</Text>}
				{!isFetchingPostsLabel &&
					!isErrorPostsLabel &&
					postsLabel &&
					postsLabel.map((post) => {
						console.log(post);
						return <PostCard key={post.id} post={post} />;
					})}
			</Center>
		</Flex>
	);
}
