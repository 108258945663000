import {Button, Box, Card, Image, Stack, CardBody, Heading, Text, Flex, Avatar, CardFooter, Center, Skeleton, SkeletonCircle, SkeletonText, useColorModeValue } from '@chakra-ui/react'
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function PostCard ({post}) {
  let navigate = useNavigate();
  const isLoadedCard = true;
  const openPost = () => {
    navigate(`/observatory/${post.post.id}`);
  };

  return (
    
    <Box as='Button' width={'100%'} maxW={'800px'} maxHeight={'30%'} onClick={openPost}>
    <Card
      direction={{ base: 'column', sm: 'row' }}
      overflow='hidden'
      variant='outline'
      
      _hover={
        {
          backgroundColor: useColorModeValue('gray.50', 'idsm.darkBlueCoolors.base'),
          borderColor: useColorModeValue('gray.200', 'idsm.darkBlueCoolors.8'),
          boxShadow: 'lg',
          transition: 'all .5s ease',
        }
      }
      _after={{
        
        content: '""',
        w: 'full',
        h: 'full',
        pos: 'absolute',
        top: 2,
        left: 0,
        objectFit: 'cover',
        backgroundColor: 'idsm.lightBlueCoolors.8',
        filter: 'blur(8px)',
        zIndex: -1,
      }}
      bg={useColorModeValue('white', 'idsm.darkBlueCoolors.7')}
      borderColor={useColorModeValue('gray.50', 'idsm.darkBlueCoolors.8')}
    >

      <Image
        objectFit='cover'
        minW={{ base: '100%', sm: '120px', md: '200px' }}
        maxW={{ base: '100%', sm: '120px', md: '200px' }}
        maxH={{ base: '200px', sm: '100%' }}
        
        alt='Caffe Latte'
        fallbackSrc={post.post.imagem}
      />

      <Stack>
        <CardBody >
            <Center flexDir={'column'}>
            <SkeletonText margin={2} skeletonHeight='8'noOfLines={1} isLoaded={isLoadedCard}>
            <Heading size='md' margin={2} >{post.post.titulo}</Heading>
            </SkeletonText>
            <SkeletonText marginLeft={'2%'} marginRight={'2%'} skeletonHeight='6' noOfLines={[3, 3, 6, 6]} isLoaded={isLoadedCard}>
              <Text py='2' textAlign={'initial'} lineHeight={'8'} noOfLines={[3, 3, 6, 6]} >
                {post.post.descricao}
              </Text>
            </SkeletonText>
            </Center>
        </CardBody>

        <CardFooter>
          <Flex flex='1' alignItems='center' justifyContent={'flex-end'}>
            <Flex gap='4' alignItems='center' flexWrap='wrap' justifyContent={'center'}>
              <SkeletonCircle size={12} isLoaded={isLoadedCard}>
                <Avatar name={post.autores[0].nome}  />
              </SkeletonCircle>
              <Box>
                <SkeletonText skeletonHeight='4'noOfLines={2} isLoaded={isLoadedCard}>
                <Heading size='sm'>{post.autores[0].formacao}</Heading>
                <Text>{post.autores[0].nome} {post.autores[0].sobrenome}</Text>
                </SkeletonText>
              </Box>
            </Flex>
          </Flex>
        </CardFooter>
      </Stack>
    </Card>
    </Box>
  )
}